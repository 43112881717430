// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
// import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"

// Importing the main application js
// import 'js/application'
import 'bootstrap'

// Importing the SCSS
import "scss/application"

Rails.start()
ActiveStorage.start()
require.context("../images", true);

import './theme'
import "controllers"
