import { Controller } from "stimulus"
import { Loader } from 'google-maps';


export default class extends Controller {

  async connect() {
    const google = await this.createGoogleClient()
    this.initAutocomplete(google)
  }

  initAutocomplete(google) {
    var baseCenter = { lat: 48.8534, lng: 2.3488 }
    const defaultBounds = {
      north: baseCenter.lat + 0.1,
      south: baseCenter.lat - 0.1,
      east: baseCenter.lng + 0.1,
      west: baseCenter.lng - 0.1,
    };
    const inputs = document.querySelectorAll("#search-form");
    const options = {
      bounds: defaultBounds,
      componentRestrictions: { country: "fr" },
      fields: ["address_components", "geometry", "icon", "name"],
      strictBounds: false,
    };
    inputs.forEach(input => {
      new google.maps.places.Autocomplete(input, options);
    })
  }

  createGoogleClient() {
    const loader = new Loader('AIzaSyAA4mVrTNzuUa5Zc8z04OdZ5qRkWQIa2Yo', {
      libraries: ['places'],
    });
    return loader.load();
  }
}